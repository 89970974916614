body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;;
}

.home_bg {
  /* height: 60rem; */
  background-image: linear-gradient(119deg, #43e695, #3bb2b8);
}

.Link {
  margin: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: right;
  color: #ffffff;
}

.JoatSpace {
  margin-left: 6.25rem;
  font-family: 'Open Sans';
  font-size: 2.2rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  text-align: left;
  color: #ffffff;
}

.JoatSpaceDesc {
  margin-left: 6.25rem;
  font-family: 'Open Sans';
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: left;
  color: #ffffff;
}

.howItWorksTitle {
  margin-top: 10rem;
}